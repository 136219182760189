//@import "~antd/dist/antd.less"  ; 

@primary-color: #5C9B9C;   
@link-color: #06a4b3;   
@border-radius-base: 8px;   
@heading-color: #1C4546;        
@font-size-base: 15px;      

.nav-wrapper { z-index: 3000; background : #F1F5F7}
a { text-decoration: none !important;}   
.ant-layout-header { position: fixed;  
    z-index: 1000;
    width: 100% !important;
    background: #FFFFFF !important; }
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {border: 0px solid #EEE !important}
.ant-layout-content { margin-top: 70px !important}
.page-container { background: #F3F2EE; padding-bottom: 80px;}
.login-page-container { padding-bottom: 80px; background: #5C9B9C;}  
.white-bg {background-color: #FFFFFF; padding: 15px}  
.green-bg {background-color: #5FCE85;}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after { border-bottom: 7px solid @primary-color !important;}
.ant-menu-horizontal { 
    border-bottom: 0px solid #f0f0f0;
    padding-top: 8px 0px;  
    .ant-menu-item{ 
        font-size: 16px;   
    }     
    .anticon{   
        font-size: 25px !important;  
    }
    button {
        margin-bottom: 8px;  
    } 
} 
.ant-btn {
    border: none !important;
}
.shad { box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;  }
.coolBox1 {
    position:absolute;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    padding: 15px;
    b {    
        display: inline-block;
        background: #FFDE08;
        color: #FE496C;
        font-weight: bolder;
        padding: 7px 12px; 
        font-size: 17px; 
    }   
    p{
        color:#FFFFFF;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
    }
} 
.borderless {
    border : 0px !important;
}
.main-content-wrapper {
    background: #F2F3F4;
    min-height: 530px;
    background: url('/drawables/bmp.jpeg'); 
    background-size: cover;
}
.wel-box {  
    padding: 15px;
    border-radius: 9px; 
    margin-top: 12px;
}  
.white-bold {
    color: #FFFFFF;
    font-weight: bold; 
}    
.uform {
    text-align: left !important; 
    input, textarea, select, div, .ant-radio-button-wrapper {
        border-radius: 3px !important;
    }
    b{
        text-align: left;
    }
}  
.light-fancy-box {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 5px;
    span {
        display: inline-block;
        padding: 6px 10px;
        background-color: #F96771; 
        font-weight : bolder;
        color: #FFFFFF;      
        transform: rotate(-5deg);
        -ms-transform: rotate(-7deg);
	    -webkit-transform: rotate(-7deg);
    }   
    button {
        background-color: #A990D9;
        color:#FFFFFF; 
        font-weight: bolder;
    }   
} 
.company-info-box{
    background: #5FCF85 !important;
    padding: 8px !important;    
}
.user-company-box { 
    padding: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin: 18px 0px;
    border-radius: 6px;
    img {
        width: 90px !important;
        height: 60px !important;
        border-radius: 60px !important; 
    }
    .desc {
        background-color: #EFF2F5;
        color: #010101;         
        padding: 7px; 
        border-radius: 5px;
    } 
}
.tilted {
    transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
	    -webkit-transform: rotate(-3deg);
}
.site-layout .site-layout-background {   
    background: #fff;     
  }  
.top-ad-box { 
    background: @primary-color;   
    .arrow_box {
        position: relative;
        background: @primary-color;
    }
    .arrow_box:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; 
        border-color: rgba(254, 73, 108, 0);
        border-right-color: @primary-color;
        border-width: 20px;
        margin-top: -20px;
    } 
  }
  .ant-layout-sider-trigger {  
      background-color: #168794 !important; 
  }
  .ant-layout { background-color: #F3F2EE;}
  .cool-radius { border-radius: 0px 12px 12px 12px; padding: 12px; margin-bottom: 10px; } 
  #prtdiv, #reprintid {
      display: none;
  }
  @media print {
    #prtdiv, #reprintid {     
        display: block;
    } 
  }
  .primary-bg {
    background: #2458A4;
  }
  .white-txt {
    color: #FFFFFF;
  }
  .r-bg {
    background-color: #EF7B7A ;
  }
  .labside {
    background: url('/drawables/labcl.png');
    background-size: contain;
  }
  .drugside {
    background: url('/drawables/drgs.jpeg');
    background-size: contain;
  }
  .glass_side {
    background: url('/drawables/lun.jpeg');
    background-size: contain;
  }
  .chi_side {
    background: url('/drawables/chi.png'); 
    background-size: contain;
  }
  .imside {
    background: url('/drawables/xray.png');
    background-size: contain;
  }
  .medside {
    background: url('/drawables/medcl.png');
    background-size: contain;
  }
  .ant-form-vertical .ant-form-item-label { padding: 0px !important;}
  .ant-form-large .ant-form-item-label > label {
    height: 20px;
    }
.user-personal-info { 
    background-color: #FCD013; 
} 
.cr-wrapper { box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; min-height: 150px; padding: 45px; border-radius: 0px 13px 13px 13px; } 
.menu_l {     
    color: #EEE !important;
}  
.menu_l:hover {
    color: #FCD013 !important; 
    text-decoration: none;
} 
.menu_l:active {
    color: #010101 !important;
}
.mdraw .ant-drawer-content {
    background: none !important;
}
.ant-layout-sider-children {
    background: #5C9B9C !important;
}
.ant-layout-sider-trigger {
    background-color: #EF7B7A !important;
    }
.digitBox {
    padding: 20px 50px;
    background-color: #CDE1E1;
    border-radius: 6px; 
}