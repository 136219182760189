// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

img { max-width: 100%;}

.lp-main-content {
    background: #F3F2EE; 
    background-size: cover;
    min-height: 600px; 
}
.textBox1 {
    border-radius: 12px;
    background: $box-color-1;
    padding: 25px;
    max-height: 280px;
    h2{ 
        font-weight: bolder;
        background-image: url('/drawables/underlined_yellow-5ef9b2644e.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }
    p {
        span {
            font-weight: bold;
        }
    }
}
.home-top-ads {
    margin-top: 15px;
    padding:15px;
    border-radius: 6px;
    background: #FFFFFF;
}
.highlight {
    display: inline-block;
    padding: 6px 13px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background: #FFDE08;
    color: #FE496C;
    font-size: 18px; 
    text-transform: uppercase;  
    font-weight: bold;
    margin-bottom: 8px;
}
.highlight-comp {
    display: inline-block;
    padding: 3px 7px;
    background: #FFDE08;
    color: #FE496C;
    font-size: 14px;  
    text-transform: uppercase;  
    font-weight: bold;
    margin-bottom: 4px;     
}
.highlight-wt {
    display: inline-block;
    padding: 3px 7px;
    background: #FFFFFF;   
    font-size: 14px; 
    text-transform: uppercase;  
    font-weight: bold;
    margin-bottom: 4px;     
} 
.home-right-box{
    background: #FFFFFF;
    min-height: 650px;
}